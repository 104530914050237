import { LocalStorageKeys } from "./../enum/enum";
import { IUser } from "@interfaces/auth.interface";
import { getUserData } from "./localStorage.util";

export const isUserLoggedIn = () => {
  if (!!getUserData()) {
    return true;
  }
  return false;
};

export const parseUser = (): IUser => {
  return JSON.parse(getUserData() || "");
};
