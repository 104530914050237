import {getLocalStorage} from "@util/localStorage.util";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import { Route, Switch } from "react-router-dom";
import MainLayout from "../components/mainLayout";
import { authRoutes } from "./auth.routes";

function Routes() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetLang =getLocalStorage("boilerplate-language") || "en"
            i18n.changeLanguage(fetLang)

    }, []);

  return (
    <Switch>
      {authRoutes}
      <Route component={MainLayout} />
    </Switch>
  );
}

export default Routes;
