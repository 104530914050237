import {
	ReconciliationOutlined,
	ShareAltOutlined,
	FileDoneOutlined,
	LockOutlined,
	ProfileOutlined,
	DatabaseOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLinks } from '~/interfaces';

const sideNavList = (t: any): NavLinks[] => {
	console.log(t, 'props');
	// const { t, i18n } = useTranslation();

	return [
		{
			href: '/profile',
			icon: <ProfileOutlined />,
			title: t('menu.profile.label'),
			rights: ['clinic', 'patient', 'sell', 'buyer'],
		},
		{
			href: '/personal-data',
			icon: <DatabaseOutlined />,
			title: t('menu.medical.label'),
			rights: ['patient', 'sell'],
		},
		{
			href: '/accessList',
			icon: <LockOutlined />,
			title: t('menu.accessList.label'),
			rights: ['clinic', 'patient', 'sell'],
		},
		{
			href: '/sharedList',
			icon: <ShareAltOutlined />,
			title: t('menu.sharedList.label'),
			rights: ['patient', 'sell'],
		},
		{
			href: '/accessLogRequest',
			icon: <FileDoneOutlined />,
			title: t('menu.accessLogRequest.label'),
			rights: ['patient', 'sell'],
		},
		{
			href: '/personalDataForConsultant',
			icon: <ReconciliationOutlined />,
			title: t('menu.medicalDataForConsultant.label'),
			rights: ['clinic'],
		},
		{
			href: '/verifiedOrganization',
			icon: <ReconciliationOutlined />,
			title: t('menu.selectClinic.label'),
			rights: ['patient'],
		},
	];
};

export default sideNavList;
