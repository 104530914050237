import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import "./App.less";
import { ErrorBoundary } from './hoc/ErrorBoundary';
import Routes from './routes';
import { store } from './store/store';
// import 'antd/dist/antd.compact.css';
// import './assets/sass/main.dark.css';
import './i18n';

function App() {
	return (
		<Suspense
			fallback={
				<div className='loader-container'>Error! Please refresh the page</div>
			}>
			<BrowserRouter>
				<ErrorBoundary>
					<Provider store={store}>
						<Routes />
					</Provider>
				</ErrorBoundary>
			</BrowserRouter>
		</Suspense>
	);
}

export default App;
