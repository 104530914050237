import { IAccessWalletRequestBody, IUser } from "@interfaces/auth.interface";
import { IHttpRes, IRequestBody } from "@interfaces/http.interface";
import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { store, v2Store } from "@util/httpUtil";
import {
  IMedicalDataResponseBody,
  IQueryRequestBody,
} from "@interfaces/medical.interface";
import { useHistory } from "react-router-dom";
import {
  addAuthToLocalStorage,
  getLocalStorage,
} from "@util/localStorage.util";
import { message } from "antd";
import axiosInstance from "~/axios/axios";
import axiosInstancev2 from "~/axios/axiosv2.ts";

export interface MedState {
  payload: any;
  response: any;
  statusMessage?: string;
  error?: SerializedError;
  loading: boolean;
}

const initialState: MedState = {
  payload: [],
  response: undefined,
  statusMessage: undefined,
  error: undefined,
  loading: false,
};

export const accessWallet = createAsyncThunk(
  "profile/access/post",
  (formData: any, { rejectWithValue }) => {
    return v2Store("api/wallets/profile/query", formData)
      .then((response) => {
        console.log(response, "serviceacces");

        if (response?.status === 200) {
          message.success("Profile detail added successfully.");
          addAuthToLocalStorage(JSON.stringify(response?.data[0]));
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addProfileRecord = createAsyncThunk(
  "profile/post",
  (formData: any, { rejectWithValue }) => {
    return v2Store("api/wallets/profile", formData)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addClinicProfileRecord = createAsyncThunk(
  "clinic/profile/post",
  (formData: any, { rejectWithValue }) => {
    return v2Store("api/wallets/clinic/profile", formData)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const verifySecretCode = createAsyncThunk(
  "verify/secret/code",
  (formData: any, { rejectWithValue }) => {
    return axiosInstancev2
      .get(`/api/wallets/secret-code/${formData}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        } else {
          // TO DO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    cleanProfileData(state) {
      state.loading = false;
      state.payload = [];
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProfileRecord.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(addProfileRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
    });
    builder.addCase(addProfileRecord.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(accessWallet.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(accessWallet.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
    });
    builder.addCase(accessWallet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { cleanProfileData } = profileSlice.actions;
