import {httpBase} from "@util/httpBaseUtil";

export function store(endpoint :any, data : any) {

    return httpBase(false)
        .post(`${endpoint}`, data);
}

export function v2Store(endpoint :any, data : any) {

    return httpBase(true)
        .post(`${endpoint}`, data);
}

