import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import {
	ArrowLeftOutlined,
	DashboardOutlined,
	DotChartOutlined,
	FundOutlined,
	TableOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import Logo from '@assets/images/logo.png';
import { ThemeContext } from '~/hoc/ThemeProvider';

interface IProps {
	hideDrawer: () => void;
	visible: boolean;
	onClose: () => void;
}

const Navdrawer = ({ visible, hideDrawer, onClose }: IProps) => {
	return (
		<div>
			<Drawer
				placement='left'
				closable={false}
				onClose={onClose}
				visible={visible}>
				<div className='drawer__header'>
					<div>
						<img src={Logo} alt='' />
					</div>
					<Button onClick={hideDrawer} className='drawer__trigger'>
						<ArrowLeftOutlined />
					</Button>
				</div>
				<Menu mode='inline' defaultSelectedKeys={['1']}>
					<Menu.Item key='1' icon={<DashboardOutlined />}>
						<Link to='/'>Default</Link>
					</Menu.Item>
					<Menu.Item key='2' icon={<DotChartOutlined />}>
						Analytics
					</Menu.Item>
					<Menu.Item key='3' icon={<FundOutlined />}>
						<Link to='/form'>Form</Link>
					</Menu.Item>
					<SubMenu icon={<UserOutlined />} key='sub1' title='Report'>
						<Menu.Item key='4' icon={<DotChartOutlined />}>
							Finance
						</Menu.Item>
						<Menu.Item key='5' icon={<FundOutlined />}>
							Marketing
						</Menu.Item>
					</SubMenu>
					<Menu.Item key='7' icon={<TableOutlined />}>
						<Link to='/tables'>Table</Link>
					</Menu.Item>
				</Menu>
			</Drawer>
		</div>
	);
};

export default Navdrawer;
