import { IRequestBody } from "@interfaces/http.interface";
import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { store, v2Store } from "@util/httpUtil";
import _ from "lodash";
import {
  IMedicalDataResponseBody,
  IQueryRequestBody,
} from "@interfaces/medical.interface";
import { addMedicalRecord } from "~/pages/MedicalData/slice";
import { message } from "antd";

export interface MedState {
  payload: any;
  id: string;
  consultation_date: string;
  consultation_number: string;
  medical_history: string;
  complete_blood_count: string;
  fasting_blood_sugar: string;
  blood_pressure: string;
  glucose_level: string;
  symptoms: string;
  objectType: string;
  created_date: string;
  patient: string;
  statusMessage?: string;
  error?: SerializedError;
  loading: boolean;
}

const initialState: MedState = {
  payload: [],
  id: "",
  consultation_date: "",
  consultation_number: "",
  medical_history: "",
  complete_blood_count: "",
  fasting_blood_sugar: "",
  blood_pressure: "",
  glucose_level: "",
  symptoms: "",
  objectType: "",
  created_date: "",
  patient: "",
  statusMessage: undefined,
  error: undefined,
  loading: false,
};

export const getAccessLogDataClinic = createAsyncThunk(
  "access-log/fetch/clinic",
  (formData: any, { rejectWithValue }) => {
    return (
      v2Store("api/access/shared-lists/patient", formData)
        // return v2Store("api/access/accessible-medical-data", formData)
        .then((response) => {
          console.log("yoo query access data by owner", response.data);
          if (response.status === 200 && response?.data) {
            return response?.data;
          }
        })
        .catch((error) => rejectWithValue(error.response.data))
    );
  }
);

// Not in use
export const getAccessLogData = createAsyncThunk(
  "access-log/fetch",
  (formData: any, { rejectWithValue }) => {
    return v2Store("api/access/access-request", formData)
      .then((response) => {
        console.log(response, "response");
        if (response.status === 200 && response?.data) {
          response.data.sort(
            (d1: any, d2: any) =>
              new Date(d2.created_date).getTime() -
              new Date(d1.created_date).getTime()
          );
          const SEPERATOR = "--";
          const groupedData = _.chain(response?.data)
            .groupBy(
              (item: any) => `${item?.patient_id}${SEPERATOR}${item?.viewer_id}`
            )
            ?.value();
          let pendingRequest = [];
          for (const accessRequest of _.toArray(groupedData)) {
            let approved = false;
            for (const request of accessRequest) {
              if (request?.status === "accepted") {
                approved = true;
              }
            }
            if (!approved) {
              pendingRequest.push(accessRequest[0]);
            }
          }
          // console.log(pendingRequest,'pending')
          return pendingRequest;
        } else {
          return [];
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const verifyAccessLogData = createAsyncThunk(
  "accessLog-approve",
  (formData: any, { rejectWithValue }) => {
    return v2Store("api/access/approve-access/request", formData)
      .then((response) => {
        message.success("Action completed successfully!");
        return Promise.resolve(response);
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);
export const accessLogApproveSlice = createSlice({
  name: "accessLog-approve",
  initialState,
  reducers: {
    cleanAccessLogApproval(state, action) {
      state.loading = false;
      state.payload = [];
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyAccessLogData.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(verifyAccessLogData.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
    });
    builder.addCase(verifyAccessLogData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const accessLogSlice = createSlice({
  name: "access-log",
  initialState,
  reducers: {
    cleanAccessLogData(state) {
      state.loading = false;
      state.payload = [];
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessLogData.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAccessLogData.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload || [];
    });
    builder.addCase(getAccessLogData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(getAccessLogDataClinic.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAccessLogDataClinic.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload || [];
    });
    builder.addCase(getAccessLogDataClinic.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { cleanAccessLogData } = accessLogSlice.actions;
export const { cleanAccessLogApproval } = accessLogApproveSlice.actions;
