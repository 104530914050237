export const organizationList: { label: string; value: string }[] = [
	{ label: 'EMID Group', value: 'Org1MSP' },
	{ label: 'Organization2 Group', value: 'Org2MSP' },
	// { label: "EMID Organization", value: "OrgemidMSP" },
];

export const registerOrganizationList: { label: string; value: string }[] = [
	{ label: 'EMID Group', value: 'Org1MSP' },
];
export enum LocalStorageKeys {
	ACCESS_TOKEN = 'accessToken',
	USER = 'user',
	THEME = 'theme',
}

export enum ThemeList {
	DARK = 'dark',
	Light = 'light',
}
