/**
 *
 * Private Route
 *
 */
import {isAllowed} from "@util/permissionUtil";
import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router";
import { parseUser } from "@util/auth.util";
import {message} from "antd";

// @ts-ignore
const PrivateRoute = ({ component: Component, ...props }) => {
  const [profileExists, setProfileExists] = useState(true);
  const user = parseUser();
    let history = useHistory();
    let location = useLocation();

  useEffect(() => {
    if (!user.id && location.pathname !== "/profile/update") {
        message.info("Please add your profile!")
        history.push('/profile/update')
    }
      if (user.id && location.pathname === "/personal-data" && isAllowed(["clinic"])) {
          history.push("/forbidden")
      }
      if (user.id && location.pathname === "/personalDataForConsultant" && isAllowed(["patient","sell"])) {
          history.push("/forbidden")
      }
  }, [user]);

  return (
    <Route
      {...props}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
