import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
	BellOutlined,
	BulbOutlined,
	CloseOutlined,
	EditOutlined,
	LogoutOutlined,
	UserOutlined,
	// MenuFoldOutlined,
	// MenuUnfoldOutlined,
	QuestionCircleOutlined,
	SearchOutlined,
	DownOutlined,
	ShopOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Avatar, Badge, Button, Dropdown, Input, Menu, Switch } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import Logo from '@assets/images/logo.png';
import DarkLogo from '@assets/images/darkLogo.png';
import Profile from '@assets/images/profile.jpg';
import {
	clearLocalStorage,
	getLocalStorage,
	clearDynamicLocalStorage,
	setLocalStorage,
} from '@util/localStorage.util';
import {
	PERMISSION_KEY,
	ORGANIZATION_KEY,
	USER_KEY,
	LANGUAGE_KEY,
} from '../constants/index';
import { ThemeContext } from '~/hoc/ThemeProvider';

const rolemap: any = {
    patient: "User",
    clinic: "Organization",
};
interface IProps {
	// changeTheme: () => void;
	handleSidebarTrigger: () => void;
	onClose: () => void;
	showDrawer: () => void;
	sidebarColllapse: boolean;
}

const Navbar = (props: IProps) => {
	const { t, i18n } = useTranslation();

	const userName = getLocalStorage('user');
	const { theme, toggleTheme } = useContext(ThemeContext);

	const history = useHistory();

	const [showResponsiveSearch, setShowResponsiveSearch] =
		useState<boolean>(false);

	const handleResponsiveSearch = () => {
		setShowResponsiveSearch(true);
	};

	const handleResponsiveSearchClose = () => {
		setShowResponsiveSearch(false);
	};

	const getFirstLetterFromFirstNameAndLastName = (name: string) => {
		if (!name) {
			return 'N A';
		}
		const names = name.split(' ');
		const firstLetter = names[0].charAt(0);
		const lastLetter = names[names.length - 1].charAt(0);
		return `${firstLetter} ${lastLetter}`;
	};

	const signOutHandler = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		e.preventDefault();
		clearLocalStorage();
		clearDynamicLocalStorage(PERMISSION_KEY);
		clearDynamicLocalStorage(ORGANIZATION_KEY);
		clearDynamicLocalStorage(USER_KEY);
		history.push('/login');
	};

	const menuNotification = (
		<Menu className='notification__dropdown'>
			<Menu.Item>
				<div className='notification__header'>
					<h4>Notification</h4>
					<Button type='text' className='clear__btn'>
						Clear
					</Button>
				</div>
			</Menu.Item>
			<Menu.Item>
				<div className='notification__list'>
					<Avatar size={40}>
						<img src={Profile} alt='' />
					</Avatar>
					<div className='comment__section'>
						<span className='commentor__name'>Erin Gonzales </span>
						<span className='comment__text'>has comment on your board</span>
					</div>
					<span className='comment__time'>7:57PM</span>
				</div>
			</Menu.Item>
			<Menu.Item>
				<div className='notification__list'>
					<Avatar size={40}>
						<img src={Profile} alt='' />
					</Avatar>
					<div className='comment__section'>
						<span className='commentor__name'>Erin Gonzales </span>
						<span className='comment__text'>has comment on your board</span>
					</div>
					<span className='comment__time'>7:57PM</span>
				</div>
			</Menu.Item>
			<Menu.Item>
				<div className='notification__list'>
					<Avatar size={40}>
						<img src={Profile} alt='' />
					</Avatar>
					<div className='comment__section'>
						<span className='commentor__name'>{userName?.fullname}</span>
						<span className='comment__text'>has comment on your board</span>
					</div>
					<span className='comment__time'>7:57PM</span>
				</div>
			</Menu.Item>
			<Menu.Item>
				<div className='notification__list'>
					<Avatar size={40}>
						<img src={Profile} alt='' />
					</Avatar>
					<div className='comment__section'>
						<span className='commentor__name'>Erin Gonzales </span>
						<span className='comment__text'>has comment on your board</span>
					</div>
					<span className='comment__time'>7:57PM</span>
				</div>
			</Menu.Item>
		</Menu>
	);

	const menuLanguage = (
		<Menu>
			<Menu.Item>
				<a
					title='en'
					onClick={(event) => {
						changeLanguage(event, 'en');
					}}>
					English
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					title='jp'
					onClick={(event) => {
						changeLanguage(event, 'jp');
					}}>
					日本
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					title='sa'
					onClick={(event) => {
						changeLanguage(event, 'sa');
					}}>
					عربى
				</a>
			</Menu.Item>
		</Menu>
	);

	const languageTitle = (languageVal: any) => {
		let LanguageTitle;
		switch (languageVal) {
			case 'sa':
				LanguageTitle = 'عربى';
				break;
			case 'jp':
				LanguageTitle = '日本';
				break;
			case 'kr':
				LanguageTitle = 'Korean';
				break;
			default:
				LanguageTitle = 'English';
		}
		return LanguageTitle;
	};

	const changeLanguage = (language: any, type: any) => {
		console.log(language, 'language');
		i18n.changeLanguage(type);
		setLocalStorage(LANGUAGE_KEY, type);
	};

	const menuProfile = (
		<Menu className='profile__dropdown'>
			<Menu.Item>
				<div className='profile__section'>
					<Avatar style={{ backgroundColor: '#87d068' }}>
						{getFirstLetterFromFirstNameAndLastName(userName?.fullname)}
					</Avatar>
					<div>
						<h4 className='user__name'>{userName?.fullname}</h4>
						<span className='work__department'>{rolemap[userName?.role] || 'organization'}</span>
					</div>
				</div>
			</Menu.Item>
			{/* <Menu.Item>
        <Link
          to="/profile/update"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          <span className="link__iocns">
            <EditOutlined />
          </span>
          Edit Profile
        </Link>
      </Menu.Item> */}
			{/*<Menu.Item>*/}
			{/*  <Link*/}
			{/*    to="#"*/}
			{/*    target="_blank"*/}
			{/*    rel="noopener noreferrer"*/}
			{/*    href="https://www.luohanacademy.com"*/}
			{/*  >*/}
			{/*    <span className="link__iocns">*/}
			{/*      <SearchOutlined />*/}
			{/*    </span>*/}
			{/*    Account Setting*/}
			{/*  </Link>*/}
			{/*</Menu.Item>*/}
			{/*<Menu.Item>*/}
			{/*  <Link*/}
			{/*    to="#"*/}
			{/*    target="_blank"*/}
			{/*    rel="noopener noreferrer"*/}
			{/*    href="https://www.luohanacademy.com"*/}
			{/*  >*/}
			{/*    <span className="link__iocns">*/}
			{/*      <ShopOutlined />*/}
			{/*    </span>*/}
			{/*    Billing*/}
			{/*  </Link>*/}
			{/*</Menu.Item>*/}
			{/*<Menu.Item>*/}
			{/*  <Link*/}
			{/*    to="#"*/}
			{/*    target="_blank"*/}
			{/*    rel="noopener noreferrer"*/}
			{/*    href="https://www.luohanacademy.com"*/}
			{/*  >*/}
			{/*    <span className="link__iocns">*/}
			{/*      <QuestionCircleOutlined />*/}
			{/*    </span>*/}
			{/*    Help Center*/}
			{/*  </Link>*/}
			{/*</Menu.Item>*/}
			<MenuItem>
				<span className='theme__switch__row'>
					<BulbOutlined />
					Switch theme &nbsp;
					<Switch checked={theme === 'dark'} onChange={toggleTheme} />
				</span>
			</MenuItem>
			<Menu.Item>
				<Link to='#' onClick={signOutHandler}>
					<span className='link__iocns'>
						<LogoutOutlined />
					</span>
					Signout
				</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<div className='page__header'>
			<div className='header__logo'>
				<img
					src={theme === 'dark' ? DarkLogo : Logo}
					width={80}
					height={80}
					alt='logo'
				/>
			</div>
			<div className='header__main'>
				<div
					className={`responsive__search ${
						showResponsiveSearch ? 'show' : ''
					}`}></div>
				{/* <Button
          className="btn__menu__toggle"
          onClick={props.handleSidebarTrigger}
        >
          {props.sidebarColllapse ? (
            <MenuUnfoldOutlined />
          ) : (
            <MenuFoldOutlined />
          )}
        </Button>
        <Button
          onClick={props.showDrawer}
          className="btn__menu__toggle drawer__trigger"
        >
          <MenuUnfoldOutlined />
        </Button> */}

				<ul className='navigation__right'>
					<Dropdown overlay={menuLanguage} trigger={['click']}>
						<a href='#'>
							{languageTitle(i18n.language)} <DownOutlined />
						</a>
					</Dropdown>
					{/* <li className="display__lg">
            <Button
              className="btn__search__sm"
              onClick={handleResponsiveSearch}
            >
              <SearchOutlined />
            </Button>
          </li> */}
					{/* <li>
            <Dropdown
              overlay={menuNotification}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Button className="btn__notification">
                <BellOutlined />
                <Badge count={5} />
              </Button>
            </Dropdown>
          </li> */}
					<li>
						<Dropdown
							overlay={menuProfile}
							placement='bottomRight'
							arrow
							trigger={['click']}>
							<Button className='btn__profile'>
								<span className='profile__image'>
									<Avatar style={{ backgroundColor: '#87d068' }}>
										{getFirstLetterFromFirstNameAndLastName(userName?.fullname)}
									</Avatar>
								</span>
							</Button>
						</Dropdown>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Navbar;
