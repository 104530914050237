import { isAllowed } from '@util/permissionUtil';
import { Layout, Menu, Typography } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NavLinks } from '~/interfaces';
import sideNavList from './sideNavList';
import { parseUser } from "@util/auth.util";

const { Header } = Layout;

function MenuHeader() {
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const user = parseUser();

	return (
		<div>
			<Layout>
				<Header>
					<Typography.Text strong>Ant Design</Typography.Text>
					<Menu
						mode='horizontal'
						defaultOpenKeys={['/' + location.pathname.split('/')[1]]}
						defaultSelectedKeys={[location.pathname]}
						theme='light'>
						{sideNavList(t).map((navLink: NavLinks) => {
							if (isAllowed(navLink.rights) && navLink.children) {
								return (
									<SubMenu
										icon={navLink.icon}
										key={navLink.href}
										title={navLink.title}>
										{navLink.children.map((childrenLink: NavLinks) => (
											<Menu.Item
												key={childrenLink.href}
												icon={childrenLink.icon}>
												<Link to={childrenLink.href}>{childrenLink.title}</Link>
											</Menu.Item>
										))}
									</SubMenu>
								);
							}
							if (isAllowed(navLink.rights)) {
								return (
									<Menu.Item key={navLink.href} icon={navLink.icon}>
										<Link to={navLink.href}>{navLink.title}</Link>
									</Menu.Item>
								);
							}
						})}
					</Menu>
				</Header>
			</Layout>
		</div>
	);
}

export default MenuHeader;
