import axios from "axios";
import { getLocalStorage }  from "@util/localStorage.util";
import {USER_KEY} from "../constants/index";

const baseURL = process.env.REACT_APP_V2_URL;
const axiosInstancev2 = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: "application/json",
    },
});

axiosInstancev2.interceptors.request.use(function (config) {
    const token = localStorage.getItem(USER_KEY);
    config.headers.Authorization =  token || '';
    return config;
});

// axiosInstance.interceptors.response.use(
//   (response: any) => {
//     return response;
//   },
//   (err) => {
//     //Handle refresh token here
//     const originalRequest = err.config;
//     if (err.response.status === 306 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       //do some stuff here........
//       return axiosInstance(originalRequest);
//     }
//     return Promise.reject(err);
//   }
// );
export default axiosInstancev2;
