import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v2Store } from "~/util/httpUtil";

export interface ClinicState {
    payload: any;
    error?: any;
    loading: boolean;
}

const initialState: ClinicState = {
    payload: [],
    error: undefined,
    loading: false,
};

export const getVerifiedClinicData = createAsyncThunk(
    'verified/clinic/fetch',
    (formData: any, { rejectWithValue }) => {
        return v2Store('/api/wallets/clinics/all', formData).then((response) => {
            if (response.status === 200 && response.data) {
                return response.data; 
            }
        }).catch((error) => rejectWithValue(error.response.data));
    }
);

export const checkIfMedicalDataExists = createAsyncThunk(
    'check-medical-data/exists/patient',
    (formData: any, { rejectWithValue }) => {
        return v2Store('/api/medical/check-medical-data-exists/patient', formData).then((response) => {
            if (response.status === 200 && response.data) {
                return response.data;
            }
        }).catch((error) => rejectWithValue(error.response.data));
    }
);

export const checkIfAccessRequestPending = createAsyncThunk(
    'check-if-access-request-pending',
    (formData: any, { rejectWithValue }) => {
        return v2Store('/api/access/check-if-request-pending', formData).then((response) => {
            if (response.status === 200 && response.data) {
                return response.data;
            }
        }).catch((error) => rejectWithValue(error.response.data));
    }
);

export const claimMedicalData = createAsyncThunk(
    'claim-medical-data/patient', 
    (formData: any, { rejectWithValue }) => {
        return v2Store('/api/medical/medical-data-owner/claim', formData).then((response) => {
            if (response.status === 200 && response.data) {
                return response.data;
            }
        }).catch((error) => rejectWithValue(error.response.data));
    }
);

export const clinicSlice = createSlice({
    name: "clinic",
    initialState,
    reducers: {
        cleanClinicData(state) {
            state.loading = false;
            state.payload = [];
            state.error = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVerifiedClinicData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getVerifiedClinicData.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
        });
        builder.addCase(getVerifiedClinicData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    }
});

export const { cleanClinicData } = clinicSlice.actions;