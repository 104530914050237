import {ReactNode} from "react";
import {IRouteItem} from "~/interfaces";
import {lazy} from "./lazy";
import PrivateRoute from "../hoc/PrivateRoute";

export const mainRoutesList: IRouteItem[] = [
    {
        key:6,
        name: "Profile",
        path: "/",
        // LazyComponent: lazy(() => import("../pages/Home/Home")),
        LazyComponent: lazy(() => import("../pages/Profile/Profile")),
        exact: true,
    },
    {
        key:7,
        name: "Profile",
        path: "/profile",
        LazyComponent: lazy(() => import("../pages/Profile/Profile")),
        exact: true,
    },
    {
        key:8,
        name: "Profile",
        path: "/profile/update",
        LazyComponent: lazy(() => import("../pages/Profile/container/ProfileUpdateContainer")),
        exact: true,
    },
    {
        key:9,
        name: "Personal Data",
        path: "/personal-data",
        LazyComponent: lazy(() => import("../pages/MedicalData/container/MedicalContainer")),
        exact: true,
    },
    {
        key:9,
        name: "Personal Data",
        path: "/personal-data/:id/detail",
        LazyComponent: lazy(() => import("../pages/MedicalData/container/MedicalDetailContainer")),
        exact: true,
    },
    {
        key:10,
        name: "Personal Data",
        path: "/personal-data/update",
        LazyComponent: lazy(() => import("../pages/MedicalData/container/MedicalUpdateContainer")),
        exact: true,
    },
    {
        key:11,
        name: "AccessList",
        path: "/accessList",
        LazyComponent: lazy(() => import("../pages/Access-List/Access-List")),
        exact: true,
    },    {
        key:12,
        name: "ShareList",
        path: "/sharedList",
        LazyComponent: lazy(() => import("../pages/Access-List/Access-List")),
        exact: true,
    },
    {
        key:13,
        name: "AccessLogRequest",
        path: "/accessLogRequest",
        LazyComponent: lazy(() => import("../pages/Access-Log/container/AccessLogContainer")),
        exact: true,
    }, {
        key:14,
        name: "PersonalDataForConsultant",
        path: "/personalDataForConsultant",
        LazyComponent: lazy(() => import("../pages/MedicalDataConsultant/container/MedicalDataLogContainer")),
        exact: true,
    },
    {
        key:15,
        name: "Forbidden",
        path: "/forbidden",
        LazyComponent: lazy(() => import("../pages/Error/Forbidden")),
        exact: true,
    },
    {
        key: 16,
        name: "SelectOrganization",
        path: "/verifiedOrganization",
        LazyComponent: lazy(() => import("../pages/SelectClinic/SelectClinic")),
        exact: true,
    },
    {
        key:17,
        name: "PersonalDataList",
        path: "/personal-data-list/:id/detail/:isAccess",
        LazyComponent: lazy(() => import("../pages/Access-List/MedicalDetailsList")),
        exact: true,
    },
    {
        key:17,
        name: "AccessHistory",
        path: "/access-history/:id/detail",
        LazyComponent: lazy(() => import("../pages/Access-List/Access-History")),
        exact: true,
    },
];

const routerDom: ReactNode[] = [];
const parseRoutes = (routeList: IRouteItem[]) => {
    routeList.forEach((route) =>
        routerDom.push(
            <PrivateRoute
                key={route.name}
                path={route.path}
                component={route.LazyComponent}
                exact={route.exact}
            />
        )
    );
    return routerDom;
};

export const mainRoutes = parseRoutes(mainRoutesList);
