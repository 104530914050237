import {
  accessLogApproveSlice,
  accessLogSlice,
} from "~/pages/Access-Log/slice";
import { medSlice } from "~/pages/MedicalData/slice";
import { profileSlice } from "~/pages/Profile/slice";
import { clinicSlice } from "~/pages/SelectClinic/slice";
import { authReducer } from "./reducers/authReducer";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    authReducer: authReducer,
    medReducer: medSlice.reducer,
    profileReducer: profileSlice.reducer,
    accessLogReducer: accessLogSlice.reducer,
    accessLogVerifiedReducer: accessLogApproveSlice.reducer,
    clinicReducer: clinicSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
