import {getLocalStorage} from "@util/localStorage.util";
import axios from 'axios';
import { parseUser } from "@util/auth.util";
import {USER_KEY} from "~/constants";

 export const httpBase = (isV2Api = false) => {
     const baseURL =  process.env.REACT_APP_BASE_URL;
     const v2BaseUrl =process.env.REACT_APP_V2_URL;
     const userId= `${getLocalStorage(USER_KEY)}`
     const normalHeaders = {
         Accept: 'application/json',
         'Content-Type': 'application/json',
     };
     const V2Headers = {
         Accept: '*/*',
         Authorization: `${userId}`,
         'Content-Type': 'application/json',
     };

    const api = axios.create({
        baseURL:(isV2Api ) ? `${v2BaseUrl}` : `${baseURL}`,
            headers: isV2Api
                ? { ...V2Headers }
                : { ...normalHeaders },
        responseType: 'json',
    }

    );

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (401 === error.response.status) {
                console.log(error,'401')
            }
            if (404 === error.response.status) {
                console.log(error,'404')
            }
            if (500 === error.response.status) {
                console.log(error,'500')
            }
            return Promise.reject(error);
        }
    );

    return api;
};
// export default httpBase;
