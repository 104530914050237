import { IRequestBody } from "@interfaces/http.interface";
import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import { store, v2Store } from "@util/httpUtil";
import {
  IMedicalDataResponseBody,
  IQueryRequestBody,
} from "@interfaces/medical.interface";

export interface MedState {
  payload: any;
  id: string;
  consultation_date: string;
  consultation_number: string;
  medical_history: string;
  complete_blood_count: string;
  fasting_blood_sugar: string;
  blood_pressure: string;
  glucose_level: string;
  symptoms: string;
  objectType: string;
  created_date: string;
  patient: string;
  statusMessage?: string;
  error?: any;
  loading: boolean;
}

const initialState: MedState = {
  payload: [],
  id: "",
  consultation_date: "",
  consultation_number: "",
  medical_history: "",
  complete_blood_count: "",
  fasting_blood_sugar: "",
  blood_pressure: "",
  glucose_level: "",
  symptoms: "",
  objectType: "",
  created_date: "",
  patient: "",
  statusMessage: undefined,
  error: undefined,
  loading: false,
};

export const getMedicalData = createAsyncThunk(
  "medicalData/fetch",
  (formData: any, { rejectWithValue }) => {
    return v2Store("api/medical/medical-data-owner/query", formData)
      .then((response) => {
        if (response.status === 200) {
          response.data.sort(
            (d1: any, d2: any) =>
              new Date(d2.created_date).getTime() -
              new Date(d1.created_date).getTime()
          );
          return response.data as IMedicalDataResponseBody;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addMedicalRecord = createAsyncThunk(
  "medical/post",
  (formData: any, { dispatch, rejectWithValue }) => {
    return v2Store("api/medical/medical-data", formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        } else {
          // TODO
        }
      })
      .catch((error) => {
        return rejectWithValue(
          error.response?.data?.error?.message || "Error sharing data"
        );
      });
  }
);

export const medSlice = createSlice({
  name: "medical",
  initialState,
  reducers: {
    cleanMedData(state) {
      state.loading = false;
      state.payload = [];
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMedicalData.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getMedicalData.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
    });
    builder.addCase(getMedicalData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(addMedicalRecord.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(addMedicalRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
    });
    builder.addCase(addMedicalRecord.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { cleanMedData } = medSlice.actions;
