// import { IAuthReducer } from "./../../core/interface/auth.interface";
import * as authActions from "../actions/auth/authActions";

const initState: any = {
  isAuth: false,
};

export const authReducer = (state = initState, action: any): any => {
  switch (action.type) {
    case authActions.CHECK_TOKEN: {
      return {
        ...state,
        isAuth: action.isAuth,
      };
    }
    default: {
      return state;
    }
  }
};
